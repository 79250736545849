@import "https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #fff;
  background: #000;
  font-family: "Press Start 2P", cursive;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

header .nav {
  background-color: red;
  padding: 20px;
}

header .title {
  margin-top: clamp(20px, 30vh, 600px);
  margin-bottom: calc(100vh - 150px);
  margin-left: clamp(2px, 10%, 200px);
}

#down {
  cursor: pointer;
  font-size: 2rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%)scale(.3);
}

#down:hover {
  filter: opacity(.5);
}

.container {
  width: 100%;
  padding: 20px;
}

.card {
  word-break: break-word;
  width: 100%;
  max-width: 600px;
  margin-bottom: 30px;
  padding: 10px;
}

.card-container {
  float: right;
  margin-right: 70px;
}

#info {
  background-color: #171717d9;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 30px;
  padding-bottom: 30vh;
  display: flex;
}

#info .link {
  align-items: center;
  gap: 10px;
  text-decoration: none;
  display: flex;
}

#info .link img {
  color: red;
  max-width: 30px;
}

#info .link:hover {
  filter: opacity(.5);
}

#info .list .card {
  background-color: #00f;
}

#info .list h1 {
  margin-bottom: 20px;
}

#info .list p {
  text-align: left;
  line-height: 20px;
}

canvas {
  z-index: -1;
  border: 2px solid red;
  position: fixed;
  top: 0;
  left: 0;
}

.linear-wipe {
  text-align: center;
  color: #000;
  text-fill-color: transparent;
  background: linear-gradient(to right, #fff 20%, #ff0 40% 60%, #fff 80%) 0 0 / 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 1s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200%;
  }
}

/*# sourceMappingURL=index.e6f6f9cb.css.map */
